import { User } from "./users";

export enum Features {
  Prescreen = "PRESCREEN",
}

export type FeaturePermissions = {
  [key in Features]: boolean;
};

export interface FormState {
  info: Info;
  dealershipId: string;
}

export interface Info {
  licenses: FeaturePermissions;
}

export type License = { _id: string; data: FormState };

export const hasFeaturePermission = (
  feature: Features,
  user?: User
): boolean => {
  if (!user) return false;

  const licenses = user?.data?.licenses?.data?.info?.licenses;
  return licenses ? licenses[feature] : false;
};
