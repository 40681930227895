import { useParams } from "react-router-dom";

import Grid from "@Components/Grid";
import Paper from "@Components/Paper";
import BackButton from "@Components/BackButton";
import { useGetPrescreen } from "@Hooks/usePrescreen";

import PrescreenView from "./PrescreenView";
import styles from "./Prescreens.module.scss";

const Prescreen = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) return <>Error</>;

  const { prescreen } = useGetPrescreen(id);
  if (!prescreen) return <>Loading...</>;
  return prescreen ? (
    <div className={styles.root}>
      <Grid container xs={12}>
        <Grid xs={1.5}>
          <BackButton />
        </Grid>
        <Grid xs={12}>
          <Paper title="Prescreen" style={{ background: "#ffffff" }}>
            <div style={{ marginTop: "40px" }}>
              <PrescreenView prescreen={prescreen?.data?.info} />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  ) : null;
};

export default Prescreen;
