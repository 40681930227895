import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
1;
import { HttpQuery } from "@Types/http";
import { MutationError } from "@Types/index";
import { Prescreen } from "@Types/prescreens";
import { getPrescreens, getPrescreen, deletePrescreen } from "@Http/prescreens";

import { queryClient } from "../App";

export const useGetPrescreens = (query?: HttpQuery) => {
  const queryKey = ["prescreens", query ?? []];

  const { data, isLoading, error } = useQuery<
    Prescreen[],
    MutationError,
    Prescreen[]
  >({
    queryKey: queryKey,
    queryFn: getPrescreens(query),
  });

  return {
    data,
    isLoading,
    error,
  };
};

export const useGetPrescreen = (id: string) => {
  const queryKey = [`prescreen_${id}`];

  const {
    data: prescreen,
    isLoading,
    error,
    isSuccess,
  } = useQuery({
    queryKey: queryKey,
    queryFn: getPrescreen(id),
  });

  return {
    isSuccess,
    prescreen,
    isLoading,
    error,
  };
};
export const useDeletePrescreen = () => {
  const navigate = useNavigate();

  const mutation = useMutation<Prescreen, MutationError, Prescreen>(
    deletePrescreen(),
    {
      onSuccess: (prescreen) => {
        navigate("/prescreens", { replace: true });
        queryClient.setQueriesData<Prescreen[]>(["prescreens"], (oldData) =>
          oldData?.filter((x) => x._id !== prescreen._id)
        );
        toast.success("Successfully deleted an user.", {
          position: "top-center",
        });
      },
    }
  );
  return {
    deletePrescreen: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};
