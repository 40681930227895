import { CreateRoleRequest } from "@Types/roles";

export const initialRoleState: CreateRoleRequest = {
  data: {
    info: {
      name: "",
      permissions: {
        deal: {
          page: "deal",
          label: "Applications",
          description: "Pages related to applications.",
          permissions: {
            owner: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            group: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            other: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
          },
          subPages: [
            {
              page: "collateral",
              hasPermissions: false,
            },
            {
              page: "trade-in",
              hasPermissions: false,
            },
            {
              page: "notes",
              isSensitive: true,
              hasPermissions: false,
            },
            {
              page: "applicants",
              hasPermissions: false,
            },
            {
              page: "documents",
              isSensitive: true,
              hasPermissions: false,
            },
            {
              page: "financials",
              isSensitive: true,
              hasPermissions: false,
            },
          ],
        },
        incomplete_application: {
          label: "Unfinished applications",
          page: "incomplete_application",
          description: "Pages related to unfinished applications.",
          permissions: {
            owner: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            group: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            other: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
          },
        },
        applicant: {
          label: "Applicants",
          page: "applicant",
          description: "Pages related to applicants.",
          permissions: {
            owner: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            group: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            other: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
          },
        },
        customer_dashboard: {
          label: "Customer Dashboard",
          page: "customer_dashboard",
          description: "Customer dashboard page.",
          permissions: {
            owner: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            group: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            other: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
          },
        },
        dealership_user: {
          page: "dealership_user",
          description: "Pages related to the app users.",
          label: "Users",
          permissions: {
            owner: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            group: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            other: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
          },
        },

        dealership_role: {
          label: "Roles",
          page: "dealership_role",
          description: "Pages related to permission roles.",
          permissions: {
            owner: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            group: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            other: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
          },
        },
        dealership_team: {
          label: "Teams",
          page: "dealership_team",
          description: "Pages related to permissions teams.",
          permissions: {
            owner: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            group: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            other: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
          },
        },
        settings: {
          label: "Settings",
          page: "settings",
          description: "Pages related to settings.",
          permissions: {
            owner: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            group: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            other: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
          },
        },
        dealership_ticket: {
          label: "Dealership tickets",
          page: "dealership_ticket",
          description: "Dealership tickets.",
          permissions: {
            owner: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            group: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            other: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
          },
        },
        title_issue: {
          label: "Title Info",
          page: "title_issue",
          description: "Pages related to title information.",
          permissions: {
            owner: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            group: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            other: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
          },
        },
        profit_dashboard: {
          label: "Profit dashboard",
          page: "profit_dashboard",
          description: "Profit dashboard",
          permissions: {
            owner: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            group: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            other: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
          },
        },

        profit_report: {
          label: "Profit Report",
          page: "profit_report",
          description: "Pages related to reports",
          permissions: {
            owner: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            group: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            other: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
          },
        },
        chat: {
          label: "Chat",
          page: "chat",
          description: "Pages related to the chat.",
          permissions: {
            owner: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            group: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            other: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
          },
        },

        dealership_notification: {
          page: "dealership_notification",
          label: "Notifications",
          description: "Sections related to notifications.",
          permissions: {
            owner: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            group: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            other: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
          },
        },
        dealership_document: {
          page: "dealership_document",
          label: "Documents",
          description: "Sections related to documents.",
          permissions: {
            owner: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            group: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            other: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
          },
        },
        chargeback: {
          page: "chargeback",
          label: "Chargebacks",
          description: "Sections related to chargebacks.",
          permissions: {
            owner: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            group: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            other: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
          },
        },

        prescreen: {
          page: "prescreen",
          label: "Prescreen",
          description: "Sections related to Prescreen.",
          permissions: {
            owner: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            group: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
            other: {
              read: {
                hasPermissions: false,
              },
              update: {
                hasPermissions: false,
              },
              create: {
                hasPermissions: false,
              },
              delete: {
                hasPermissions: false,
              },
            },
          },
        },
      },
    },
  },
};
