import { BiUserCircle } from "react-icons/bi";
import { MdMoneyOff } from "react-icons/md";
import { FaUserCheck } from "react-icons/fa";
import { Link, Outlet, useLocation } from "react-router-dom";
import { RxDashboard, RxFileText, RxPerson } from "react-icons/rx";

import Search from "@Components/Search";
import ColoredLogo from "../ColoredLogo";

import UserMenu from "@Components/UserMenu";
import styles from "./Layout.module.scss";

import Chat from "@Components/Chat";
import Notifications from "@Components/Notifications";
import WhatsNew from "@Components/WhatsNew";
import useCurrentUser from "@Hooks/useCurrentUser";
import { User } from "@Types/users";
import { capitalize } from "@Utils/functions";
import { hasActionPermissions } from "@Utils/permissions";
import mixpanel from "mixpanel-browser";
import { Fragment, useEffect, useState } from "react";
import { AiOutlineLineChart, AiOutlineTeam } from "react-icons/ai";
import { BsChatText } from "react-icons/bs";
import { IoPersonOutline, IoSettingsOutline } from "react-icons/io5";
import { MdOutlineSecurity } from "react-icons/md";
import { TbFileUnknown, TbReportAnalytics } from "react-icons/tb";
import Tickets from "@Components/Tickets";
import { hasFeaturePermission, Features } from "@Types/licenses";

const getPageFromPath = (path: string) => {
  const pathAsArray = path.split("/").filter((x) => x);
  if (pathAsArray.length === 2) {
    if (pathAsArray[1] === "new") {
      return {
        event: `Visited new ${capitalize(pathAsArray[0])} page`,
        id: undefined,
      };
    } else {
      return {
        event: `Visited individual ${capitalize(pathAsArray[0])} page`,
        id: pathAsArray[1],
      };
    }
  }
  if (pathAsArray.length === 1) {
    return {
      event: `Visited page ${capitalize(pathAsArray[0])}`,
      id: undefined,
    };
  }
  return { event: "Visited unknown page", id: undefined };
};

const menuLinks = [
  {
    text: "Dashboard",
    id: "dashboard",
    icon: <RxDashboard />,
    path: "/dashboard",
  },
  {
    text: "Prescreen",
    id: "prescreens",
    icon: <FaUserCheck />,
    path: "/prescreens",
    show: (user?: User) => {
      return (
        hasActionPermissions("prescreen", "read", user) &&
        hasFeaturePermission(Features.Prescreen, user)
      );
    },
  },
  {
    text: "Applications",
    id: "applications",
    icon: <RxFileText />,
    path: "/applications",
    show: (user?: User) => {
      return hasActionPermissions("deal", "read", user);
    },
  },
  {
    text: "Applicants",
    id: "applicants",
    icon: <RxPerson />,
    path: "/applicants",
    show: (user?: User) => {
      return hasActionPermissions("applicant", "read", user);
    },
  },

  {
    text: "Dashboards",
    id: "dashboards",
    icon: <TbReportAnalytics />,
    path: undefined,
    show: (user?: User) => {
      return (
        hasActionPermissions("profit_dashboard", "read", user) ||
        hasActionPermissions("customer_dashboard", "read", user)
      );
    },
    entities: [
      {
        text: "Profit Dashboard",
        id: "profit_dashboard",
        icon: <AiOutlineLineChart />,
        path: "/profit_dashboard",
        show: (user?: User) => {
          return hasActionPermissions("profit_dashboard", "read", user);
        },
      },
      {
        text: "Customer Dashboard",
        id: "customer_dashboard",
        icon: <RxPerson />,
        path: "/customer_dashboard",
        show: (user?: User) => {
          return hasActionPermissions("customer_dashboard", "read", user);
        },
      },
    ],
  },
  {
    text: "Users",
    id: "members",
    icon: <BiUserCircle />,
    path: "/members",
    show: (user?: User) => {
      return hasActionPermissions("dealership_user", "read", user);
    },
  },

  {
    text: "Title Info",
    id: "titleIssues",
    icon: <TbFileUnknown />,
    path: "/title_info",
    show: (user?: User) => {
      return hasActionPermissions("title_issue", "read", user);
    },
  },
  {
    text: "Chat",
    id: "chat",
    icon: <BsChatText />,
    path: "/chat",
    show: (user?: User) => {
      return hasActionPermissions("chat", "read", user);
    },
  },
  {
    text: "Reports",
    id: "reports",
    icon: <TbReportAnalytics />,
    path: undefined,
    show: (user?: User) => {
      return hasActionPermissions("profit_report", "read", user);
    },
    entities: [
      {
        text: "Profit Report",
        id: "profit_report",
        icon: <TbReportAnalytics />,
        path: "/profit_report",
        show: (user?: User) => {
          return hasActionPermissions("profit_report", "read", user);
        },
      },
    ],
  },
  {
    text: "Permissions",
    id: "permissions",
    icon: <MdOutlineSecurity />,
    path: undefined,
    show: (user?: User) => {
      return hasActionPermissions("dealership_role", "read", user);
    },
    entities: [
      {
        text: "Roles",
        id: "roles",
        icon: <IoPersonOutline />,
        path: "/roles",
        show: (user?: User) => {
          return hasActionPermissions("dealership_role", "read", user);
        },
      },
      {
        text: "Teams",
        id: "teams",
        icon: <AiOutlineTeam />,
        path: "/teams",
        show: (user?: User) => {
          return hasActionPermissions("dealership_team", "read", user);
        },
      },
    ],
  },
  {
    text: "Chargebacks",
    id: "chargebacks",
    icon: <MdMoneyOff />,
    path: "/chargebacks",
    show: (user?: User) => {
      return hasActionPermissions("chargeback", "read", user);
    },
  },
  {
    text: "Settings",
    id: "settings",
    icon: <IoSettingsOutline />,
    path: "/settings",
    show: (user?: User) => {
      return hasActionPermissions("settings", "read", user);
    },
  },
];
const Layout = () => {
  const { pathname } = useLocation();
  const user = useCurrentUser();
  const [nestedItemActive, setNestedItemActive] = useState<number | undefined>(
    undefined
  );
  useEffect(() => {
    const { event, id } = getPageFromPath(pathname);
    mixpanel.track(event, {
      email: user?.data?.info?.email,
      dealershipName: user?.data?.dealership?.data?.info?.name,
      dealershipInfo: JSON.stringify(user?.data?.dealership?.data?.info),
      ...(id ? { entityId: id } : {}),
    });
  }, [pathname]);

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
      }}
    >
      <div
        style={{
          borderRight: "1px solid #D9D9D9",
          minHeight: "100vh",
          boxSizing: "border-box",
          width: 271,
          display: "inline-block",
        }}
      >
        <div className={styles.logoContainer}>
          <ColoredLogo />
        </div>
        <div className={styles.menu}>
          {menuLinks.map(({ icon, text, path, entities, id, show }, index) => {
            if (Array.isArray(entities)) {
              if (!show || show(user)) {
                return (
                  <Fragment key={index}>
                    <div
                      id={`nav-menu-${id || "link" + index}`}
                      onClick={() =>
                        setNestedItemActive((prev) => {
                          if (typeof prev === "number" && prev !== index) {
                            return undefined;
                          }
                          return index;
                        })
                      }
                      className={[
                        styles.menuItem,
                        nestedItemActive === index
                          ? styles.activeMenuItemWithEntities
                          : "",
                      ].join(" ")}
                    >
                      <div className={styles.menuItemInfo}>
                        {icon}
                        {text}
                      </div>
                    </div>
                    {nestedItemActive === index &&
                      entities.map((entity, entityIndex) => {
                        if (!entity.show || entity.show(user)) {
                          return (
                            <div
                              id={`nav-menu-${id || "link" + entityIndex}`}
                              key={entityIndex}
                              className={[
                                styles.menuItemSecondary,
                                pathname.includes(entity.path)
                                  ? styles.activeNestedMenuItem
                                  : "",
                              ].join(" ")}
                            >
                              <Link to={entity.path}>
                                {entity.icon}
                                {entity.text}
                              </Link>
                            </div>
                          );
                        }
                        return null;
                      })}
                  </Fragment>
                );
              }
            } else if (path) {
              if (!show || show(user)) {
                return (
                  <div
                    id={`nav-menu-${id || "link" + index}`}
                    key={index}
                    onClick={() => setNestedItemActive(undefined)}
                    className={[
                      styles.menuItem,
                      pathname.includes(path) ? styles.activeMenuItem : "",
                    ].join(" ")}
                  >
                    <Link to={path}>
                      {icon}
                      {text}
                    </Link>
                  </div>
                );
              }
            }
          })}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          width: "calc(100% - 311px)",
          flexDirection: "column",
        }}
      >
        <div className={styles.search}>
          <Search />
          <WhatsNew />
        </div>
        <div
          style={{
            display: "grid",
            marginTop: "10px",
            marginBottom: "20px",
            margin: 20,
            overflow: "auto",
          }}
        >
          <Outlet />
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <Tickets />

        {hasActionPermissions("dealership_notification", "read", user) && (
          <Notifications />
        )}
        <UserMenu />
        {pathname !== "/chat" && hasActionPermissions("chat", "read", user) && (
          <Chat />
        )}
      </div>
    </div>
  );
};

export default Layout;
