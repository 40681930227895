import axios from "axios";
import { HttpQuery } from "@Types/http";
import { auth } from "@Firebase/firebase";
import { CreatePrescreenRequest, Prescreen } from "@Types/prescreens";

export const createPrescreen = (): ((
  prescreen: CreatePrescreenRequest
) => Promise<Prescreen>) => {
  return async (prescreen) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/prescreen/`,
      prescreen,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };
};
export const getPrescreens = (
  query?: HttpQuery
): (() => Promise<Prescreen[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/prescreen/`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          query:
            query && Object.keys(query)?.length
              ? JSON.stringify(query)
              : undefined,
        },
      }
    );

    return response.data.result?.docs ?? [];
  };
};

export const getPrescreen = (id: string): (() => Promise<Prescreen>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/prescreen/${id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.result;
  };
};
export const deletePrescreen = (): ((
  prescreen: Prescreen
) => Promise<Prescreen>) => {
  return async (prescreen) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.delete(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/prescreen/${prescreen._id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };
};
